import React, { Component } from "react";
import { injectIntl, intlShape } from "react-intl";
import { ReportTable, ReportOptionsContainer, ReportTypes } from "../components";
import { withReport } from "../hocs";

import { FieldAPI, ReportAPI, ReportBooksAPI } from "@ai360/core";
import "../reports.css";
import { ICrops, ISeason } from "@ai360/core/dist/4x/es/api/field/field";
import { IReportType, IReportTypePage } from "../data/interfaces";

const service = {
    REPORT_TYPE_LIST_URL: ReportAPI.GET_REPORT_TYPE_LIST_URL,
    REPORT_TYPE_PAGE_LIST_URL: ReportAPI.GET_REPORT_TYPE_PAGE_LIST_URL,
};
export interface AGRIntelligence_Props {
    createReport?: () => void;
    createReportHubProgress?: boolean;
    initialRequestOptions?: Record<string, any>;
    intl: intlShape;
    needs?: () => void;
    onAllReportCreated?: () => void;
    reportPreferences?: Record<string, any>;
    reportTypes?: IReportType[];
    reportTypePages?: IReportTypePage[];
    selectReportType?: () => void;
    selectedReportTypes?: string[];
    selectedReportTypeNames?: string[];
}
export class AGRIntelligence_ extends Component<AGRIntelligence_Props> {
    static defaultProps = {
        reportTypes: [],
        reportTypePages: [],
    };

    /// Event Handlers ///
    ///--------------------------///
    render(): JSX.Element {
        const { formatMessage } = this.props.intl;
        /// Property set up, etc. ///
        return (
            <div className="reports-container">
                <div className="reports-options-container">
                    <ReportTypes
                        selectReportType={this.props.selectReportType}
                        reportTypes={this.props.reportTypes}
                        selectedReportTypes={this.props.selectedReportTypes}
                        formatMessage={formatMessage}
                    />
                    <ReportOptionsContainer
                        createReport={this.props.createReport}
                        reportPreferences={this.props.reportPreferences}
                        reportTypePages={this.props.reportTypePages}
                        selectedReportTypes={this.props.selectedReportTypeNames}
                    />
                </div>
                <ReportTable
                    createReportHubProgress={this.props.createReportHubProgress}
                    initialRequestOptions={this.props.initialRequestOptions}
                    needs={this.props.needs}
                    onAllReportCreated={this.props.onAllReportCreated}
                    url={ReportAPI.GET_REPORT_LIST_URL}
                    autoSearchUrl={ReportAPI.GET_REPORT_AUTO_SEARCH_URL}
                    selectAllUrl={ReportAPI.GET_REPORT_SELECT_ALL_URL}
                />
            </div>
        );
    }
}

export const AGRIntelligence = withReport(injectIntl(AGRIntelligence_), service);
