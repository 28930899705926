import React from "react";
import { injectIntl, intlShape } from "react-intl";

import { Checkbox, DateInput, Section, SelectInput, SubSection } from "~/core";
import CroppingSeason from "~/reports/components/inputs/cropping-season";
import { ACTIVE_YN } from "~/core/picklist";
import { messages } from "~/reports/i18n-messages";
import { model } from "~/reports/data";
import { getReportDataByReportType, getCropOptionsData } from "~/reports/data/selectors";

import { FieldAPI } from "@ai360/core";
import { useAppDispatch, useAppSelector } from "~/store/hooks";
import { ReportOptionsComponent, ReportOptionsComponentProps } from ".";
import { getFieldsPerPageOptions } from "../utils";
import { setReportDataValue } from "../data/actions";

type ReportBookReportOptionsProps = {
    intl: intlShape;
};

function ReportBookReportOptions({ intl: { formatMessage } }: ReportBookReportOptionsProps) {
    const dispatch = useAppDispatch();

    const onInputChange = (key: string, value: any) =>
        dispatch(
            setReportDataValue({ key, value, reportType: ReportBookReportOptions.reportName })
        );
    const reportData = useAppSelector(
        getReportDataByReportType(ReportBookReportOptions.reportName)
    );
    const crops = useAppSelector(getCropOptionsData);

    const croppingSeasonDisabled = !!(
        reportData[model.PROPS_START_DATE] || reportData[model.PROPS_END_DATE]
    );

    return (
        <Section className="report-options">
            <SubSection>
                <CroppingSeason
                    disabled={croppingSeasonDisabled}
                    required={!croppingSeasonDisabled}
                    reportType={ReportBookReportOptions.reportName}
                />
                <SelectInput<FieldAPI.ICrop>
                    disabled={false}
                    placeholderText={formatMessage(messages.crop)}
                    options={crops}
                    onChange={(value) => {
                        onInputChange(model.PROPS_CROP_GUID, value ? value.cropGuid : null);
                    }}
                    value={reportData[model.PROPS_CROP_GUID]}
                />
            </SubSection>
            <SubSection>
                <DateInput
                    disabled={false}
                    placeholderText={formatMessage(messages.startDate)}
                    onChange={(value) =>
                        onInputChange(model.PROPS_START_DATE, value ? value : null)
                    }
                    timeFormat={false}
                    value={reportData[model.PROPS_START_DATE]}
                />
                <DateInput
                    disabled={false}
                    placeholderText={formatMessage(messages.endDate)}
                    onChange={(value) => onInputChange(model.PROPS_END_DATE, value ? value : null)}
                    timeFormat={false}
                    value={reportData[model.PROPS_END_DATE]}
                />
            </SubSection>
            <SubSection>
                <SelectInput
                    required
                    clearable={false}
                    optionIsHiddenKey={ACTIVE_YN}
                    containerClassNames={["display-options-list"]}
                    placeholderText={formatMessage(messages.reportBookGroupByOption)}
                    options={getFieldsPerPageOptions()}
                    onChange={(value) => {
                        if (value != "field") {
                            onInputChange(model.PROPS_INDIVIDUAL_RB, false);
                        }
                        onInputChange(model.PROPS_REPORT_BOOK_GROUP_BY, value);
                    }}
                    value={reportData[model.PROPS_REPORT_BOOK_GROUP_BY]}
                />
            </SubSection>
            <SubSection>
                <Checkbox
                    className={""}
                    disabled={reportData[model.PROPS_REPORT_BOOK_GROUP_BY] != "field"}
                    label={formatMessage(messages.individualReportBook, {})}
                    onChange={(e, value) => onInputChange(model.PROPS_INDIVIDUAL_RB, value)}
                    value={reportData[model.PROPS_INDIVIDUAL_RB]}
                />
            </SubSection>
        </Section>
    );
}

ReportBookReportOptions.propTypes = {
    intl: intlShape,
};

ReportBookReportOptions.defaultReportOptions = {
    [model.PROPS_INDIVIDUAL_RB]: false,
    [model.PROPS_REPORT_BOOK_GROUP_BY]: "grower",
};

ReportBookReportOptions.reportPreferences = [];

ReportBookReportOptions.reportName = "ReportBook";

export default injectIntl(ReportBookReportOptions);
